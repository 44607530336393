<script lang="ts">
	import { browser } from "$app/environment";
	import { title } from "$lib/global/store";
  const pageTitle = "";

	import { onMount } from 'svelte';
	import { goto } from "$app/navigation";
	import auth from '$lib/auth';
	import Button from "$lib/Button.svelte";
	import { isAuthenticated } from '$lib/auth/store';

	import logo_fallback from "$lib/assets/company_logo_default.svg"
	import SectionCard from "$lib/SectionCard.svelte";
	import ReviewStars from "$lib/ReviewStars.svelte";

	import user_icon_1 from "$lib/assets/user_icon_1.png"
  import user_icon_2 from "$lib/assets/user_icon_2.png"
  import user_icon_3 from "$lib/assets/user_icon_3.png"
  const user_icons = [
    user_icon_1,
    user_icon_2,
    user_icon_3,
  ];

  const user_icon = user_icons[Math.floor(Math.random() * user_icons.length)];

  let response = {
    company_reviews: [],
  }

	if (browser) {
		const url = new URL(window.location.href);
		if (url.searchParams.has("code") && url.searchParams.has("state")) {
			onMount(async () => {
				const auth0Client = await auth.createClient();
				// appState を取り出すことができるが、現状何も入れていない。リダイレクト先とかがあればここで参照する
				console.log(await auth0Client.handleRedirectCallback());
				$isAuthenticated = true;

        if (typeof gtag !== 'undefined') {
          gtag("event", "login", {
            method: "auth0",
          });
        }

				// NOTE: URLから code, state パラメータを消す (特に動作上の必要性はない)
				url.searchParams.delete("code");
				url.searchParams.delete("state");
				window.history.replaceState(null, "", url.toString());
			});
		}

		onMount(async () => {
      const auth0Client = await auth.createClient();
      let accessToken = null;
      try {
        accessToken = await auth0Client.getTokenSilently();
      } catch (error) {
        // accessToken の取得失敗時
        if (error.message === "Login required") {
          // do nothing
        } else {
          console.log(error.message);
        }
      }
      const res = await fetch(`/index_.json`, {
        headers: accessToken ? {
          Authorization: `Bearer ${accessToken}`
        } : {},
      })

      const json = await res.json();
      if (!res.ok) {
        console.log(json);
        return;
      }
      response = json;
    });
  }


  const career_status_mapping = [
    "フルタイム勤務",
    "時短勤務",
    "産休・育休中",
    "休職中",
    "退職済",
    "学生（学生インターン含む）",
  ];

  const last_type_of_employment_mapping = [
    "正社員（総合職）",
    "正社員（エリア総合職）",
    "正社員（一般職・事務職）",
    "契約社員",
    "派遣社員",
  ];

  const occupation_category_mapping = [
    "営業",
    "サービス・販売",
    "管理",
    "事務",
    "経営・事業企画",
    "マーケティング",
    "Webサービス企画・UIUX",
    "ITエンジニア",
    "機械・電気・電子・半導体（技術職）",
    "化学・繊維・食品（技術職）",
    "建築・土木・設備（技術職）",
    "メディカル（専門職）",
    "金融（専門職）",
    "不動産（専門職）",
    "コンサルタント・専門職",
    "クリエイティブ",
    "サービス・小売・運輸",
    "その他",
  ];

  const last_job_position_mapping = [
    "経営層・役員",
    "部長（ディレクター）クラス",
    "課長（リーダー）クラス",
    "役職なし",
  ];

  const salary_range_mapping = [
    "0～100万円",
    "101万～300万円",
    "301万～350万円",
    "351万〜400万円",
    "401万〜450万円",
    "451万〜500万円",
    "501万～600万円",
    "601万～800万円",
    "801万～1000万円",
    "1001万～1200万円",
    "1201万～1500万円",
    "1501万～1800万円",
    "1801万～2000万円",
    "2001万円以上",
    "答えたくない",
  ];

  const representative_comment_field_mapping = {
    "benefits_others": "女性の働きやすさをサポートする企業独自の福利厚生制度",
    "comments_about_diversity_related_episode": "会社・上司が多様性（働きやすい環境づくり）をサポートしていると思う・思わないエピソード",
    "comments_about_work_environment": "職場環境（時間・場所・フレックス・リモートワークの頻度と実施しやすさ）",
    "comments_about_understanding_with_sudden_events": "突発対応（子ども都合（発熱等）による急な休みや遅刻・早退といった時間の調整可否）とそれに対する周囲の理解、柔軟性",
    "comments_about_career_advancement_with_having_children": "子どもがいても、キャリアアップを目指せる環境だと感じますか（評価制度、労働時間のコントロール、成長機会・研修等）",
    "comments_about_satisfaction_with_work_and_compensation": "個人が仕事にやりがいが感じられるような会社・組織の仕組みになっているかどうか。また、仕事に対する報酬への納得感の有無",
    "comments_about_diversity_equity_and_inclusion": "ジェンダーギャップ是正、ダイバーシティへのコミットメントについて、経営層へのメッセージ",
  };
</script>

<svelte:head>
	<title>{title(pageTitle)}</title>
  <meta property="og:title" content={title(pageTitle)}>
</svelte:head>

<section id="first-view-bg" class="flex">
	<section id="first-view" class="h-80 flex-1 flex flex-col items-center justify-center">
		<p class="mx-5 font-bold text-white text-xl text-center text-shadow leading-relaxed">
			<span class="whitespace-nowrap">女性視点</span>での<span class="whitespace-nowrap">働きやすさ</span>が<span class="whitespace-nowrap">わかる</span><span class="whitespace-nowrap">職場</span>の<span class="whitespace-nowrap">クチコミ</span>投稿<span class="whitespace-nowrap">プラットフォーム</span>
		</p>

		<div class="mt-10 flex flex-col items-stretch">
			<a class="flex-1" href="/companies">
				<Button classes="w-full text-center">職場のクチコミを見る</Button>
			</a>
      <!-- フォーム内製化に伴い、クチコミを2件以上投稿するフローが消滅したのでボタンを隠した -->
			<!-- <a class="flex-1 mt-4" href="/users/register" target="_blank">
				<Button classes="w-full text-center">クチコミを投稿する</Button>
			</a> -->
		</div>
	</section>
</section>

<section class="mt-5 mb-20">
	<p class="my-5 text-center">最近投稿されたクチコミ</p>
  <!-- {#each response.companies as company (company.name)} -->
	{#each response.company_reviews as review (review.id)}
		<section class="company-review">
			<div class="flex flex-row items-center">
				{#if review.company.logo_url}
					<div class="company-logo">
						<img src={review.company.logo_url} alt="{review.company.name}のロゴ" />
					</div>
				{:else}
					<div class="company-logo-placeholder">
						<img src={logo_fallback} alt="企業ロゴなし" />
					</div>
				{/if}
				<div>
					<span>
						<a href={`/companies/${review.company.id}`}>{review.company.name}</a>
					</span>
				</div>
			</div>
			<div class="mt-4 border-t-[1px]"></div>
			<p class="mt-5 mb-4 font-bold text-sm">{representative_comment_field_mapping[review.representative_comment.key]}</p>

      <div class="relative">
        {#if !review.representative_comment.is_omitted}
          <p class="text-sm">{review.representative_comment.text}</p>
          <div class="mt-5 text-center leading-none">
            <a class="text-xs" href="/companies/{review.company.id}#reviews">続きを読む &gt;&gt;</a>
          </div>
        {:else}
          <div class="absolute h-full w-full flex items-center justify-center">
            <div class="space-y-1 text-center px-5">
              <p class="text-primary-main font-bold text-sm leading-snug mb-2">クチコミの閲覧にはご自身のクチコミ投稿が必要です</p>
              <a class="inline-block" href="/auth/register">
                <Button>クチコミを投稿する</Button>
              </a>
            </div>
          </div>
          <div class="masked-review p-4 mb-16" />
        {/if}
        <div class="mt-5 text-sm">
          <div class="bg-primary-lighter flex items-center rounded p-2.5 mb-2">
            {#if !review.representative_comment.is_omitted}
              <img height="40" width="40" style="width: 40px; height: 40px;" src={user_icon} class="rounded-[50%] mr-2.5" alt="人のアイコン">
              <div>
                <p class="text-xs leading-tight text-main mb-1">{career_status_mapping[review.career_status]
                }・{occupation_category_mapping[review.occupation_category]
                }・{last_job_position_mapping[review.last_job_position]
                }・{last_type_of_employment_mapping[review.last_type_of_employment]
                }・{salary_range_mapping[review.salary_range]
                }・お子様{#if review.has_child}あり{:else}なし{/if}</p>
                <ReviewStars point={review.review_rate} starSize={"16"} />
              </div>
            {:else}
              <img height="40" width="40" style="width: 40px; height: 40px;" src={user_icon} class="rounded-[50%] mr-2.5 opacity-50" alt="人のアイコン">
              <div class="flex-1">
                <div class="masked-reviewer p-4" />
                <div class="blur-sm">
                  <ReviewStars point={3.33} starSize={"16"} />
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
		</section>
	{/each}
</section>

<style>
	#first-view-bg {
		position: relative;
	}
	#first-view-bg:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -10;
		/* @apply flex-1; */
		background-image: url(https://prod-assets.watashimo.app/top_22be3848.jpg);
    background-position: right;
		/* filter: brightness(0.8) contrast(1.4); */
		@apply bg-cover;
		@apply w-full;
		/* depends header height */
		/* height: calc(100vh - 64px); */
	}

	#first-view {
	}

	.text-shadow {
		text-shadow: 0px 0px 3px rgb(45 45 45 / 80%);
	}

	.company-review {
		@apply mx-2.5;
		@apply my-5;
		@apply p-2.5;
		@apply bg-white;
    @apply rounded-[4px];
		@apply shadow-main-shadow;
	}

  .company-logo,
  .company-logo-placeholder {
    @apply w-20;
    @apply h-20;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply mr-4;
  }

  .company-logo-placeholder {
    @apply bg-black;
    @apply bg-opacity-[8%];
    @apply rounded;
  }

  .masked-review {
    @apply flex;
    @apply items-center;
    @apply h-[120px];
    background: url("https://prod-assets.watashimo.app/masked.png") 0 0;
  }

  .masked-reviewer {
    @apply flex;
    @apply items-center;
    @apply h-[48px];
    background: url("https://prod-assets.watashimo.app/masked.png") 0 0;
  }
</style>
